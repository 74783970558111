<template>
  <v-row align="center" justify="center">
    <v-col lg="4" md="4" sm="6" cols="12" class="text-center section-title">
      <div class="im_box mt--30">
        <div class="thumbnail">
          <img
            class="w-100"
            :src="
              require('../../assets/images/pricing/assistance_login_alexa.jpg')
            "
            alt="connection Alexa"
          />
        </div>
        <div class="content">
          <div class="inner">
            <div class="content_heading">
              <h4 class="heading-title">
                Je me connecte avec mon compte Amazon de mon enceinte Alexa
              </h4>
              <v-col lg="12" class="d-flex ">
                <div style="text-align: center">
                  <div class="customGPlusSignIn" v-on:click="goToAmazon">
                    <v-img
                      id="customLWABtn"
                      src="@/assets/images/icons/amazon_312x64.png"
                      alt="Login in                     with Amazon"
                      max-width="260"
                    />
                  </div>
                </div>
              </v-col>
            </div>
            <div class="content_footer">
              Vous rencontrez un problème pour vous connecter ? <br />
              Accédez à notre FAQ en cliquant
              <router-link to="/alexa">
                <span style="color:white; text-decoration:underline"
                  >ici
                </span></router-link
              >
            </div>
          </div>
        </div>
      </div>
    </v-col>
    <v-col lg="4" md="4" sm="6" cols="12">
      <div class="im_box mt--30" style="text-align: center">
        <div class="thumbnail">
          <router-link to="/blog-details">
            <img
              class="w-100"
              :src="
                require('../../assets/images/pricing/assistance_login_google.jpg')
              "
              alt="connection Google"
            />
          </router-link>
        </div>
        <div class="content">
          <div class="inner">
            <div class="content_heading">
              <h4 class="heading-title">
                Je me connecte avec mon compte Google de mon enceinte Google
                home
              </h4>

              <v-col lg="12" class="d-flex ">
                <div id="gSignInWrapper" style="text-align: center">
                  <div
                    id="customBtn"
                    class="customGPlusSignIn"
                    v-on:click="goToGoogle"
                  >
                    <span class="iconGoogle"></span>
                    <span class="buttonText">Sign in with Google</span>
                  </div>
                </div>
              </v-col>
            </div>
            <div class="content_footer">
              Vous rencontrez un problème pour vous connecter ? <br />
              Accédez à notre FAQ en cliquant
              <router-link to="/google">
                <span style="color:white; text-decoration:underline"
                  >ici
                </span></router-link
              >
            </div>
          </div>
          <router-link
            class="transparent_link"
            to="/blog-details"
          ></router-link>
        </div>
      </div>
    </v-col>
    <v-col lg="4" md="4" sm="6" cols="12">
      <div class="im_box mt--30">
        <div class="thumbnail">
          <router-link to="/blog-details">
            <img
              class="w-100"
              :src="require('../../assets/images/pricing/apple2.jpg')"
              alt="connection Google"
            />
          </router-link>
        </div>
        <div class="content">
          <div class="inner">
            <div class="content_heading">
              <h4 class="heading-title">
                Je préfère utiliser mon compte Apple
              </h4>
              <div id="appleSignInWrapper" style="text-align: center">
                <div
                  id="customAppleBtn"
                  class="customAppleSignIn"
                  v-on:click="goToApple"
                >
                  <span class="iconApple"></span>
                  <span class="buttonText">Sign in with Apple</span>
                </div>
              </div>
            </div>
            <div class="content_footer">
              Attention, vous devez utiliser le même compte Apple sur votre
              assistant.
            </div>
          </div>
          <router-link
            class="transparent_link"
            to="/blog-details"
          ></router-link>
        </div>
      </div>
    </v-col>
    <!-- End Blog Area  -->
  </v-row>
</template>

<style type="text/css">
#customBtn {
  display: inline-block;
  background: white;
  color: #444;
  width: 190px;
  border-radius: 5px;
  border: thin solid #888;
  box-shadow: 1px 1px 1px grey;
  white-space: nowrap;
}

#customAppleBtn {
  align-content: center;
  display: inline-block;
  background: white;
  color: #444;

  border-radius: 5px;
  border: thin solid #888;
  box-shadow: 1px 1px 1px grey;
  white-space: nowrap;
}

#customBtn:hover,
#customLWABtn:hover,
#customAppleBtn:hover {
  cursor: pointer;
}

span.label {
  font-family: serif;
  font-weight: normal;
}

span.iconGoogle {
  background: url("../../assets/images/icons/google-normal.png") transparent 5px
    50% no-repeat;
  display: inline-block;
  vertical-align: middle;
  width: 42px;
  height: 42px;
}

span.iconApple {
  background: url("../../assets/images/icons/apple-black.png") transparent 5px
    no-repeat;
  background-size: contain;
  display: inline-block;
  vertical-align: middle;
  width: 42px;
  height: 42px;
}

span.buttonText {
  display: inline-block;
  vertical-align: middle;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 14px;
  font-weight: bold;
  /* Use the Roboto font that is loaded in the <head> */
  font-family: "Roboto", sans-serif;
}

.content_heading h4.heading-title {
  color: white;
  text-align: center;
  margin-bottom: 50px !important;
}

.im_box .content .inner {
  bottom: 75px;
}

.content_footer {
  bottom: 15px !important;
  color: white;
  font-size: small;
  text-align: center;
}
</style>
<script>
export default {
  components: {},
  data() {
    return {
      breadcrumbs: [
        {
          text: "Home",
          to: "/",
          disabled: false
        },
        {
          text: "Login",
          to: "",
          disabled: true
        }
      ]
    };
  },
  methods: {
    goToAmazon() {
      return this.$Amplify.Auth.federatedSignIn({
        provider: "LoginWithAmazon"
      });
    },
    goToApple() {
      return this.$Amplify.Auth.federatedSignIn({
        provider: "SignInWithApple"
      });
    },
    goToGoogle() {
      return this.$Amplify.Auth.federatedSignIn({
        provider: "Google"
      });
    }
  }
};
</script>
