<template>
  <div>
    <!-- Start Breadcrump Area  -->
    <div
      class="rn-page-title-area ptb--60 bg_image bg_image--27"
      data-black-overlay="7"
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <div
              class="text-center blog-single-page-title pt--100"
              v-if="showMenu"
            >
              <h1 class="heading-title theme-gradient">
                Mon Compte
              </h1>
            </div>
            <div class="text-center blog-single-page-title" v-else>
              <h1 class="heading-title theme-gradient" style="font-size:32px">
                Mon Compte
              </h1>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->
    <!-- Start Blog Area  -->
    <div class="rn-blog-area ptb--60 bg_color--5">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="text-center section-title">
              <p class="description">
                Pour créer ou accéder à votre compte merci de vous connecter
                avec votre compte Google, Amazon ou Apple.
                <br />
                <!--Google ou Amazon en fonction de votre équipement. -->
              </p>
            </div>
          </v-col>
        </v-row>
        <Connect v-if="showMenu" />
        <ConnectoAuth v-if="!showMenu" />
      </v-container>
    </div>
    <v-col cols="12">
      <div class="text-center section-title">
        <p style="font-size: 8px;padding-bottom: 10px">
          Si vous utilisez l'application Mes amis de confiance sur Google Home,
          vous autorisez Google à contrôler vos appareils
        </p>
      </div>
    </v-col>
    <!-- End Blog Area  -->
  </div>
</template>

<script>
import Connect from "../../components/pricing-plan/Connect";
import ConnectoAuth from "../../components/pricing-plan/ConnectoAuth";
import { mapGetters } from "vuex";
export default {
  components: { Connect, ConnectoAuth },
  computed: {
    ...mapGetters(["showMenu"])
  }
};
</script>
